import { useState } from "react";
import cx from "classnames";

import "./App.css";
import TheBand from "./components/the-band";
import Events from "./components/events";
import Media from "./components/media";
import SetList from "./components/setlist";
import Contact from "./components/contact";

const sections = ["The Band", "Event Calendar", "Set List", "Media", "Contact"];

const components = {
  "The Band": <TheBand />,
  Media: <Media />,
  "Set List": <SetList />,
  "Event Calendar": <Events />,
  Contact: <Contact />,
};

function App() {
  const [section, setSection] = useState("The Band");
  return (
    <div className="App">
      <header className="App-header">
        <h1>East of Friday</h1>
      </header>
      <header className="sub-header">
        {sections.map((s) => (
          <div
            className={cx("section", { active: section === s })}
            key={s}
            onClick={() => setSection(s)}
          >
            {s}
          </div>
        ))}
      </header>
      <div className="section-body">{components[section]}</div>
    </div>
  );
}

export default App;
