import React from "react"

const EventSection = ({ title, events }) => (
  <>
    <div className="major">{title}</div>
    {!events.length && (
      <div>
        Nothing at the moment.{" "}
        <a href="mailto:eastoffriday.band@gmail.com">Book us!</a>
      </div>
    )}
    <div className="events">
      {events.map(
        ({ date, venue, address, city, time, note, highlight }, index) => {
          return (
            <div className="event">
              <div className="date-time">
                <p className="date">{date}</p>
                {time && <p className="time">{time}</p>}
                {highlight && <div className="highlight">{highlight}</div>}
              </div>
              <div className="location">
                <p className="venue">{venue}</p>
                {address && <p className="address">{address}</p>}
                <p className="city">{city}</p>
              </div>
              <div className="note">{note}</div>
            </div>
          )
        }
      )}
    </div>
  </>
)

export default EventSection
