import React from "react"
import eofLogo from "../images/eof-logo.jpg"
import "./contact.css"

const Contact = () => (
  <div className="contact">
    <div className="details">
      <h2 className="mail">
        <a href="mailto:eastoffriday.band@gmail.com">Email the band</a>
      </h2>
      <div className="social">
        <h4>Visit us on social</h4>
        <ul className="icons">
          <li>
            <a
              href="https://facebook.com/eastOfFriday"
              className="icon fa-facebook"
            >
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/@east-of-friday"
              className="icon fa-youtube"
            >
              <span className="label">YouTube</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <span className="logo">
      <img className="crow-logo" src={eofLogo} alt="band logo" />
    </span>
  </div>
)

export default Contact
