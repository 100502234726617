import React from "react"
import cx from "classnames"
import EventSection from "./event-section"
import HiddenDoorPoster from "../images/hidden-door-poster.jpg"
import "./events.css"

const recentEvents = [
  {
    date: "The Hidden Door BBQ, Shoreline, March 16th, 2024",
  },
]

const clubs = [
  "Aurora Borealis, Shoreline",
  "O'Malley's Irish Pub, Tacoma",
  "The Skylark Cafe, West Seattle",
  "Purdy's, Sumner",
  "Tim's Tavern, Seattle",
  "The Royal Bear, Algona",
  "56th St Liquor Saloon, Tacoma",
  "The Hidden Door",
]

const Events = () => (
  <div className="events-wrapper">
    <div className="float-child">
      <img className="poster" src={HiddenDoorPoster} alt="Hidden Door Poster" />
    </div>
    <div className="float-child event-details">
      <EventSection title="Recent Events" events={recentEvents} />
      <div className="major played">Clubs We've Played</div>
      <div className="clubs">
        {clubs.map((club, index) => {
          return (
            <div className={cx("club", { even: index % 2 === 0 })}>{club}</div>
          )
        })}
      </div>
    </div>
  </div>
)

export default Events
