import React from "react"
import "./media.css"

const Media = () => (
  <>
    <iframe
      className="video"
      title="youtube-video"
      src="https://www.youtube.com/embed/rQUtKpf11zk"
      frameborder="0"
      // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allow=""
      allowfullscreen
    ></iframe>
    <h4 className="more">
      For more video visit us on{" "}
      <a
        href="https://www.youtube.com/@east-of-friday"
        target="_blank"
        rel="noreferrer"
      >
        YouTube @east-of-friday
      </a>
    </h4>
  </>
)

export default Media
